






























import { computed, defineComponent } from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { defineComponentTranslations } from '~/utils/i18n'
import { ONE_DAY } from '~/constants/duration'
import { useCookies } from '~/compositions/useCookies'
import { saleRequestsCreditsGlobalAlertDismissedCookieName } from '~/constants/sale-requests'

export default defineComponent({
  setup() {
    const { state: userState } = useNamespacedStore<UserState>(USER_NS)
    const cookies = useCookies()

    const saleRequestsInfo = computed(() => userState.saleRequests)

    function onDismiss() {
      cookies.set(saleRequestsCreditsGlobalAlertDismissedCookieName, 1, {
        maxAge: ONE_DAY
      })
    }

    return {
      saleRequestsInfo,
      onDismiss
    }
  },
  i18n: defineComponentTranslations({
    sale_requests_no_visibility_alert_text: {
      en:
        'Your credits are not enough to appear in the sale requests. You need at least {credits} paid credits.',
      el:
        'Τα credits σου δεν επαρκούν για να εμφανίζεσαι στα αιτήματα αγοράς. Χρειάζεσαι τουλάχιστον {credits} πληρωμένα credits.'
    },
    not_visible_in_sale_requests: {
      en: 'You are not visible in sale requests',
      el: 'Δεν εμφανίζεσαι στα αιτήματα αγοράς'
    }
  })
})
