


















import { capitalizeAllWords } from '~/utils/string'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  computed: {
    btnText() {
      return `"${capitalizeAllWords(this.$t('reset password') as string)}"`
    }
  }
})
