







import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    flashData: {
      type: Object,
      required: true
    }
  },
  computed: {
    email() {
      return this.flashData.email || '<>'
    }
  }
})
