














































































































































import { ciAngleRightLight } from '~/icons/source/solid/angle-right-light'
import { mapActions, mapGetters } from 'vuex'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import { defineComponent, useFetch } from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'

export default defineComponent({
  components: {
    CImg
  },
  props: {
    footerMenu: {
      type: Array,
      required: true
    },
    siteName: {
      type: String,
      required: false,
      default: 'Car.gr'
    },
    showPoweredBy: {
      type: Boolean,
      required: false,
      default: false
    },
    creationYear: {
      type: [String, Number],
      required: false,
      default: '2002'
    },
    privacyLink: {
      type: String,
      required: false,
      default: ''
    },
    availableLocales: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  setup() {
    const { dispatch: pageDispatch } = useNamespacedStore(PAGE_NS)

    useFetch(async () => {
      await pageDispatch('addBodyClass', 'has-footer')
    })
  },
  computed: {
    ...mapDeps({ languageSwitcher: LanguageSwitcher }),
    ...mapGetters(I18N_NS, { localeIsActive: 'localeIsActive' }),
    currentYear() {
      return new Date().getFullYear()
    },
    icons() {
      return {
        angleRight: ciAngleRightLight
      }
    }
  },
  beforeDestroy() {
    this.removeBodyClass('has-footer')
  },
  methods: {
    ...mapActions(PAGE_NS, {
      addBodyClass: 'addBodyClass',
      removeBodyClass: 'removeBodyClass'
    })
  }
})
