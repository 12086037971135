




































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  useStore,
  useRoute
} from '~/utils/nuxt3-migration'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { Timeout } from '~/models/timing/timeout'
import { useWebSocket, WebSocketStatus } from '~/compositions/web-socket'
import {
  NotificationEvent,
  NotificationTypeEnum
} from '~/models/notification-center/types'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { toCamelCase } from '~/utils/object'
import { useDep } from '~/compositions/dependency-container'
import RouteGuardService from '~/services/RouteGuardService'
import NotificationEntry from '~/components/shared/configurable/notification-center/NotificationEntry.vue'
import {
  ADD_NOTIFICATION_CENTER_ROW,
  INCREASE_UNREAD_MESSAGES,
  INCREASE_UNREAD_NOTIFICATIONS
} from '~/store/modules/shared/user/mutation-types'
import { changeFaviconNotification } from '~/utils/dom'

export default defineComponent({
  components: { NotificationEntry },
  setup() {
    const store = useStore()
    const notifications = ref<NotificationEvent[]>([])
    const route = useRoute()

    const {
      state: userState,
      dispatch: userDispatch,
      commit: userCommit,
      getters: userGetters
    } = useNamespacedStore<UserState>(USER_NS)

    const isPc = computed(() => store.getters[`${USER_AGENT_NS}/isPc`])
    const isAdmin = computed(() => userGetters('isAdmin'))
    const routeGuardService = useDep(RouteGuardService)

    const transitionName = ref('notifications-right')

    interface NotificationTimeout {
      id: number
      timeout: Timeout
    }
    const notificationTimeouts = ref<NotificationTimeout[]>([])

    const isAuthenticated = computed(() => routeGuardService.userIsLoggedIn())
    const relayUrl = computed(() => userState.notificationRelayUrl)

    const { open, status, close } = useWebSocket(relayUrl, {
      onMessage: (_ws, event) => {
        const socketMessage: NotificationEvent = toCamelCase(
          JSON.parse(event.data)
        )

        showNotification(socketMessage)
      }
    })

    const onWindowFocus = () => {
      if (
        isAuthenticated.value &&
        !isAdmin.value &&
        !isPc.value &&
        status.value !== WebSocketStatus.OPEN
      ) {
        open()
      }
    }
    const onWindowBlur = () => {
      if (!isPc.value) {
        // close socket on mobile window blur
        close()
      }
    }

    onMounted(() => {
      if (isAuthenticated.value && !isAdmin.value) {
        open()
        if (!isPc.value) {
          window.addEventListener('focus', onWindowFocus)
          window.addEventListener('blur', onWindowBlur)
        }
      }
    })

    const maxToShow = computed(() => {
      return isPc.value ? 3 : 1
    })

    const notificationsToShow = computed(() => {
      return [...notifications.value].splice(0, maxToShow.value)
    })
    const restOfNotifications = computed(() => {
      return [...notifications.value].splice(maxToShow.value)
    })

    const showNotification = (notification: NotificationEvent) => {
      if (
        notification.type === NotificationTypeEnum.NEW_USER_MESSAGE &&
        route.value.name === '__account_messages'
      ) {
        onNotificationClick(notification)
        return
      } else if (userState.notificationsCenter?.notifications?.rows) {
        userCommit(ADD_NOTIFICATION_CENTER_ROW, notification)
      }
      notifications.value.unshift(notification)
      userCommit(INCREASE_UNREAD_NOTIFICATIONS)
      if (notification.type === NotificationTypeEnum.NEW_USER_MESSAGE) {
        userCommit(INCREASE_UNREAD_MESSAGES)
      }

      if (!isAdmin.value) {
        changeFaviconNotification(true)
      }

      setNotificationTimeout(notification)
    }

    const removeNotification = (notification: NotificationEvent) => {
      const foundIndex = notifications.value.findIndex(
        n => n.id === notification.id
      )
      if (foundIndex > -1) {
        notifications.value.splice(foundIndex, 1)
      }
    }

    const onRemoveOnly = (notification: NotificationEvent) => {
      transitionName.value = 'notifications-right'
      removeNotification(notification)
    }
    const onNotificationClick = async (notification: NotificationEvent) => {
      transitionName.value = 'notifications-right'
      removeNotification(notification)

      if (notification.isRead) {
        return
      }

      if (notification.targetUrl) {
        // since we are going to navigate there, read the notification
        await userDispatch('readNotification', notification)
      }
    }

    const onNotificationMouseOver = (notification: NotificationEvent) => {
      if (isPc.value) {
        clearNotificationTimeout(notification)
      }
    }
    const onNotificationMouseLeave = (notification: NotificationEvent) => {
      if (isPc.value) {
        setNotificationTimeout(notification, 2000)
      }
    }
    const clearNotificationTimeout = (notification: NotificationEvent) => {
      const foundIndex = notificationTimeouts.value.findIndex(
        nt => nt.id === notification.id
      )
      if (foundIndex > -1) {
        clearTimeout(notificationTimeouts.value[foundIndex].timeout)
        notificationTimeouts.value.splice(foundIndex, 1)
      }
    }

    const setNotificationTimeout = (
      notification: NotificationEvent,
      duration: number = 6000
    ) => {
      notificationTimeouts.value.push({
        id: notification.id,
        timeout: setTimeout(() => {
          transitionName.value = restOfNotifications.value.length
            ? 'notifications-list'
            : 'notifications-right'
          removeNotification(notification)
          clearNotificationTimeout(notification)
        }, duration)
      })
    }

    return {
      notifications,
      onNotificationMouseOver,
      onNotificationMouseLeave,
      onNotificationClick,
      notificationsToShow,
      restOfNotifications,
      transitionName,
      isPc,
      onRemoveOnly
    }
  }
})
