









import {
  computed,
  defineComponent,
  onMounted,
  useRoute
} from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import GoogleLoginService from '~/services/login/GoogleLoginService'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { useLogger } from '~/compositions/logger'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  setup() {
    const [googleLoginService, legacyUrlService] = useDeps(
      GoogleLoginService,
      LegacyUrlService
    )
    const logger = useLogger()
    const snackbar = useSnackbar()
    const { t } = useI18n()
    const route = useRoute()

    const { dispatch: userDispatch } = useNamespacedStore<UserState>(USER_NS)

    const clientId = computed(() => {
      return process.env.GOOGLE_OAUTH_CLIENT_ID || ''
    })

    const loginUrl = computed(() => {
      return `https://${
        process.env.DOMAIN
      }${legacyUrlService.getUserLoginUrl()}`
    })

    onMounted(() => {
      googleLoginService.showOneTap()
      window.handleOneTapCredentialResponse = async (response: any) => {
        try {
          const { redirectUrl } = await googleLoginService.backendLogin(
            undefined,
            response.credential
          )
          if (
            redirectUrl &&
            ['__login', '__guest_register'].includes(route.value.name)
          ) {
            window.location = redirectUrl
          } else {
            await userDispatch('loadUserSubscribable')
          }
        } catch (error) {
          logger.captureError(error)
          snackbar.error(t('an error occurred please try again later'))
        }
      }
    })

    return { clientId, legacyUrlService, loginUrl }
  },
  i18n: defineComponentTranslations({
    successful_login: {
      en: 'Successful login!',
      el: 'Επιτυχής σύνδεση!'
    }
  })
})
