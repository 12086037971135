











































import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { format } from '~/utils/date'
import { ONE_DAY } from '~/constants/duration'
import { computed, defineComponent } from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { useCookies } from '~/compositions/useCookies'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'
import { subscriptionExpiryAlertDismissedCookieName } from '~/constants/subscription'

export default defineComponent({
  props: {
    expiresSoon: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { state: userState } = useNamespacedStore<UserState>(USER_NS)
    const cookies = useCookies()
    const { t } = useI18n()

    const subscription = computed(() => userState.subscription)

    const title = computed(() =>
      subscription.value?.expired
        ? t('subscription_expired')
        : t('subscription_expires_soon')
    )

    const formattedExpirationDate = computed(() => {
      if (!subscription.value) {
        return ''
      }

      return format(new Date(subscription.value.expires))
    })

    function handleAlertDismiss() {
      cookies.set(subscriptionExpiryAlertDismissedCookieName, 1, {
        maxAge: ONE_DAY
      })
    }

    return {
      title,
      formattedExpirationDate,
      subscription,
      handleAlertDismiss
    }
  },
  i18n: defineComponentTranslations({
    subscription_expires_soon: {
      en: 'Your subscription expires soon',
      el: 'Η συνδρομή σου λήγει σύντομα'
    },
    subscription_expired: {
      en: 'Your subscription has expired',
      el: 'Η συνδρομή σου έχει λήξει'
    },
    subscription_expiry: {
      en: 'Subscription expiry',
      el: 'Λήξη συνδρομής'
    },
    subscription_expired_classifieds_removed_soon: {
      en:
        'Your subscription has expired, soon your classifieds will be removed from the system',
      el:
        'Η συνδρομή σου έληξε, οι αγγελίες σου θα διαγραφούν απο το σύστημα σύντομα'
    },
    to_prevent_your_subscription_from_expiring: {
      en: 'To prevent your subscription from expiring, you need to renew it',
      el: 'Για να μην λήξει η συνδρομή σου, πρέπει να την ανανεώσεις'
    }
  })
})
