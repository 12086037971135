




































import {
  computed,
  defineComponent,
  ref,
  useRoute,
  watch
} from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import { ciGlobe } from '~/icons/source/light/globe'
import { I18N_NS, I18nState } from '~/store/modules/shared/i18n/state'
import { useNamespacedStore } from '~/compositions/store'
import { ciGreek } from '~/icons/source/brand/greek'
import { ciEnglish } from '~/icons/source/brand/english'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'

export default defineComponent({
  setup() {
    const route = useRoute()
    const { getters: i18nGetters } = useNamespacedStore<I18nState>(I18N_NS)
    const languageSwitcher = useDep(LanguageSwitcher)
    const { state: classifiedSearchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )

    const availableLocales = ref(languageSwitcher.getAvailableLocales())

    const currentRoute = computed(() => classifiedSearchState.currentRoute)

    watch(currentRoute, val => {
      availableLocales.value = languageSwitcher.getAvailableLocales(val)
    })

    watch(route, () => {
      availableLocales.value = languageSwitcher.getAvailableLocales()
    })

    const locale = computed(() => i18nGetters('locale'))

    const flags = computed(() => ({ el: ciGreek, en: ciEnglish }))

    return {
      availableLocales,
      ciGlobe,
      locale,
      languageSwitcher,
      flags
    }
  }
})
